import $ from 'jquery';

$(document).ready(function() {
  const el_autohide = $('.autohide');
  const navbar_height = $('.banner').height();

  $('body').css({
    'padding-top': navbar_height + 'px',
  });

  if (el_autohide) {
    let last_scroll_top = 0;

    $(window).scroll(function() {
      let scroll_top = $(this).scrollTop();

      if (scroll_top < last_scroll_top) {
        el_autohide.removeClass('scrolled-down');
        el_autohide.addClass('scrolled-up');
      } else {
        el_autohide.removeClass('scrolled-up');
        el_autohide.addClass('scrolled-down');
      }

      last_scroll_top = scroll_top;
    });
  }
});
