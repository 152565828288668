// import external dependencies
import jquery from 'jquery';
global.$ = global.jQuery = jquery;

import 'slick-carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/offcanvas';

import './focus';
// import './load';
import './scroll';
import './slide';
import './share';
