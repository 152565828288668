/* global sprite_path */

import $ from 'jquery';

const prevArrow = `<div class="arrow-slick arrow-slick-prev" id="arrow-slick-prev"><svg class="svg-left-arrow-dims"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="${sprite_path}#left-arrow"></use></svg></div>`;
const nextArrow = `<div class="arrow-slick arrow-slick-next" id="arrow-slick-next"><svg class="svg-right-arrow-dims"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="${sprite_path}#right-arrow"></use></svg></div>`;

var $status = $('.paginginfo');
var $slickElement = $('.slideshow');

$slickElement.on('init reInit afterChange', function(event, slick, currentSlide){
    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
    var i = (currentSlide ? currentSlide : 0) + 1;
    $status.html(i + '</span><span>' + slick.slideCount);
});

$(document).ready(function($) {
  $('.slick-slider').slick({
    arrows: true,
    prevArrow: prevArrow,
    nextArrow: nextArrow,
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 980,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 740,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  });
});