import $ from 'jquery';

$(function () {
  // Share on mobile
  const shareData = {
    title: $('h1').text(),
    url: window.location.href,
  };

  const resultPara = $('.result');

  $(document).on('click', '#share_button', function () {
    try {
      navigator.share(shareData).then(function () { });
      resultPara.text('La page a bien été partagée');
    } catch (err) {
      resultPara.text('Une erreur est survenue: ' + err);
    }
  });
});
